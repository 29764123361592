.about-container {
  margin: 0 auto;
  padding: 50px 20px;
  position: relative;
  width: 100%;
  min-height: 100vh; // Ensure full height coverage
  background: url('../../../public/assets/backgroundpatike.jpg') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center; // Center content vertically
  align-items: center; // Center content horizontally
  text-align: center;
  box-sizing: border-box;
  z-index: 1;

  .about-header {
    margin-bottom: 40px;

    h1 {
      font-size: 3rem; // Large screen font size
      font-weight: 800;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #fff;
      margin: 0;
    }

    p {
      font-size: 1.5rem; // Large screen font size for subtitle
      font-weight: 600;
      letter-spacing: 2px;
      margin-top: 10px;
      color: #fff;
    }
  }

  .about-content {
    font-size: 1.2rem;
    line-height: 1.8;
    font-weight: 500;
    max-width: 900px; // Maximum width for readability on large screens
    padding: 0 20px;

    p {
      margin-bottom: 20px;
    }
  }

  .about-location {
    margin-top: 30px;

    p {
      font-size: 1.2rem;
      color: #fff;
      font-weight: 600;
    }
  }

  // Responsive Styles
  @media (max-width: 768px) {
    padding: 40px 15px;

    .about-header {
      h1 {
        font-size: 2.5rem; // Adjusted for tablets
        letter-spacing: 2px;
      }

      p {
        font-size: 1.3rem; // Adjusted for tablets
      }
    }

    .about-content {
      font-size: 1.1rem; // Adjusted for tablets
      line-height: 1.7;
      max-width: 100%; // Full width on smaller screens

      p {
        margin-bottom: 15px; // Reduced space between paragraphs
      }
    }

    .about-location {
      p {
        font-size: 1.1rem; // Adjusted for tablets
      }
    }
  }

  @media (max-width: 480px) {
    padding: 20px;

    .about-header {
      h1 {
        font-size: 2rem; // Smaller font for mobile devices
      }

      p {
        font-size: 1.2rem; // Further adjusted for mobile
      }
    }

    .about-content {
      font-size: 1rem; // Adjusted for mobile
      line-height: 1.6;
    }

    .about-location {
      p {
        font-size: 1rem; // Adjusted for mobile
      }
    }
  }
}
