.admin-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  
    
    .navbar {
      padding: 20px;
      background: #FEFEFE;
      color: #000;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 160px; // Increased to accommodate the button group
      
      h1 {
        margin: 0;
        font-size: 24px;
      }
      
      p {
        margin: 5px 0;
      }
    }
    
    .button-group {
      display: flex;
      justify-content: center;
      gap: 15px;
      margin: 10px 0; // Adds space between the title and the buttons
  
      .button-link {
        padding: 10px 20px;
        font-size: 16px;
        color: white;
        background-color: #2980b9;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        transition: background-color 0.3s ease, transform 0.3s ease;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  
        &:hover {
          background-color: #1f618d;
          transform: translateY(-2px);
        }
  
        &:active {
          background-color: #1a5276;
          transform: translateY(0);
        }
      }
    }
    
    .content {
      flex: 1;
      padding: 20px;
      background: #f5f7fa;
    }
  }
  