.card-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #FEFEFE;

  .content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center; // Center align content horizontally
    padding: 20px;
    
    main {
      max-width: 900px;
      width: 100%;
      padding: 30px;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      font-family: 'Roboto', sans-serif;
    }

    h1 {
      font-size: 2.5rem;
      color: #333;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 1.8rem;
      color: #555;
      margin-bottom: 15px;
    }

    h3 {
      font-size: 1.4rem;
      color: #666;
      margin-bottom: 10px;
    }

    form {
      display: grid;
      gap: 20px;
      margin-bottom: 20px;

      label {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        color: #333;

        input {
          margin-top: 8px;
          padding: 12px;
          font-size: 1rem;
          border: 1px solid #ddd;
          border-radius: 6px;
          transition: border-color 0.3s;

          &:focus {
            border-color: #007bff;
            outline: none;
          }
        }
      }
    }

    .cart-items {
      display: grid;
      gap: 15px;
      margin-top: 20px;

      .cart-item-card {
        padding: 15px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

        h4 {
          font-size: 1.2rem;
          color: #333;
          margin-bottom: 5px;
        }

        p {
          font-size: 1rem;
          color: #555;
        }
      }
    }

    .confirmation-message {
      font-size: 1.2rem;
      color: #28a745;
      margin: 20px 0;
    }

    .order-button,
    .cancel-button {
      padding: 12px 20px;
      font-size: 1rem;
      color: #fff;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      transition: background-color 0.3s;

      &.order-button {
        background-color: #28a745; // Green for "Place Order"
      }

      &.cancel-button {
        background-color: #dc3545; // Red for "Cancel"
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }
}
