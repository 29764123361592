.gallery-section {
  position: relative;
  width: 100%;
  min-height: 100vh; // Ensure full height coverage
  background: url('../../../public/assets/patika.jpg') no-repeat center center;
  background-size: cover;
  background-attachment: fixed; // Background image remains fixed
  color: #fff;
  padding: 50px 20px;
  box-sizing: border-box;
  z-index: 1;

  .gallery-header {
    text-align: center;
    margin-bottom: 40px;

    h1 {
      font-size: 2.5rem; // Large font size for the main heading
      font-weight: 700;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 1.5rem; // Slightly smaller font for the subheading
      font-weight: 400;
    }
  }

  .gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    .gallery-item {
      max-width: 300px;
      width: 100%;
      border-radius: 8px; // Rounded corners for images
      overflow: hidden;

      .gallery-image {
        border-radius: 8px; // Match border-radius of the container
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Optional shadow for better appearance
      }
    }
  }

  // Responsive Styles
  @media (max-width: 768px) {
    padding: 40px 15px;

    .gallery-header {
      h1 {
        font-size: 2rem; // Adjusted for tablets
      }

      h2 {
        font-size: 1.3rem; // Adjusted for tablets
      }
    }

    .gallery-container {
      gap: 15px; // Reduced gap for tablets
    }

    .gallery-item {
      max-width: 250px; // Adjusted max-width for tablets
    }
  }

  @media (max-width: 480px) {
    padding: 20px;

    .gallery-header {
      h1 {
        font-size: 1.5rem; // Adjusted for mobile devices
      }

      h2 {
        font-size: 1.1rem; // Adjusted for mobile devices
      }
    }

    .gallery-container {
      gap: 10px; // Reduced gap for mobile devices
    }

    .gallery-item {
      max-width: 200px; // Adjusted max-width for mobile devices
    }
  }
}
