.ponuda-container { 
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  width: 100%;
  height: auto; // Allow the height to expand dynamically
  background: url('../../../public/assets/patika.jpg') no-repeat center center;
  background-size: cover;
  color: #fff;
  padding: 50px 20px;
  margin-top: -80px; // Isto koliko iznosi negativna margina na .hero

  h2 {
    font-size: 2rem;
    color: #fff;
    margin-bottom: 20px;
  }

  .products-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .products-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
    gap: 20px; 
    width: 100%; // Ensures the grid expands dynamically
  }

  .order-button-container {
    margin-top: 20px;
  }

  .cart-count {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #fff;
  }

  .order-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;

    &:hover {
      background-color: #0056b3;
    }
  }

  .success-message {
    color: green;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .product-item {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    }

    h3 {
      font-size: 1.5rem;
      color: #000;
      margin-bottom: 10px;
    }

    .product-image {
      width: 100%;
      height: 200px; // Adjusted image height
      object-fit: cover;
      border-radius: 8px;
      margin-bottom: 10px;
    }

    p {
      font-size: 1rem;
      color: #333;

      strong {
        color: #000;
      }
    }

    .add-to-cart-button {
      margin-top: 10px;
      padding: 10px 15px;
      font-size: 1rem;
      color: #fff;
      background-color: #007bff;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

// Responsive tweaks for smaller devices
@media (max-width: 768px) {
  .ponuda-container {
    padding: 15px;
    margin-top: -70px; // Isto koliko iznosi negativna margina na .hero
  }

  .products-list {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); // Adjust for smaller screens
  }

  .product-item {
    padding: 10px;

    h3 {
      font-size: 1.3rem;
    }

    p {
      font-size: 0.9rem;
    }

    .product-image {
      height: 150px; // Adjusted for smaller screens
    }
  }

  .order-button {
    width: 100%; // Make the order button full width on small screens
  }
}
