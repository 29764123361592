.orders-container {
  padding: 20px;
  background-color: #f9f9f9;
  font-family: 'Roboto', sans-serif;

  h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }

  .orders-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;

    thead {
      background-color: #007bff;
      color: #fff;

      th {
        padding: 12px;
        text-align: left;
        font-size: 1rem;
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f2f2f2;
        }

        td {
          padding: 12px;
          font-size: 0.9rem;
          color: #555;

          div {
            margin-bottom: 4px;
          }
        }

        .status-badge {
          display: inline-block;
          padding: 4px 8px;
          border-radius: 12px;
          color: #fff;
          font-size: 0.9rem;
          text-align: center;
          width: 100px; // Adjust width as needed
        }

        .delete-button {
          padding: 6px 12px;
          font-size: 0.9rem;
          color: #fff;
          background-color: #dc3545;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s;

          &:hover {
            background-color: #c82333;
          }
        }

        select {
          padding: 6px 12px;
          font-size: 0.9rem;
          border: 1px solid #ddd;
          border-radius: 4px;
          margin-right: 8px; // Add some spacing from the delete button
        }
      }
    }
  }

  @media (max-width: 768px) {
    .orders-table {
      display: block;
      width: 100%;
      font-size: 0.8rem;

      thead {
        display: none; // Hide table headers on mobile
      }

      tbody {
        tr {
          display: block;
          margin-bottom: 0px;
          border: 1px solid #ddd;
          border-radius: 4px;
          padding: 20px;
          background-color: #fff;
          position: relative;
        }

        td {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          border-top: 1px solid #ddd;
          position: relative;
          min-width: 0; // Ensure cells can shrink if needed

          &:first-child {
            border-top: none;
          }

          &:before {
            content: attr(data-label);
            font-weight: bold;
            color: #333;
            white-space: nowrap;
            margin-right: 10px; // Spacing between label and data
            flex: 1;
          }

          span {
            flex: 2;
            text-align: right; // Align data to the right
          }
        }

        .status-badge,
        .delete-button,
        select {
          display: block; // Keep these visible on mobile
          margin-bottom: 8px; // Add some spacing on mobile
        }
      }
    }
  }
}
