.navbar {
    background-color: #FEFEFE; // White background
    color: #333333; // Black text
  
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative; // Needed for absolute positioning of mobile menu icon
    }
  
    .logo {
      display: flex;
      align-items: center;
      flex-shrink: 0; // Prevent logo from shrinking
  
      img {
        height: 3.5rem; // Default logo height
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-left: 5rem;
      }
  
      span {
        color: #333333; // Black text
        font-size: 1.25rem; // Default font size
        font-weight: bold;
        margin-left: 0.5rem; // Adjust if needed
      }
    }
  
    .nav-links {
      display: flex;
      justify-content: center;
      gap: 1.5rem; // Adjust if needed
      color: #333333; // Black text
      font-weight: bold; // Make text bold
      position: absolute;
      left: 50%;
      transform: translateX(-50%); // Center the links
  
      a {
        text-decoration: none;
        color: inherit;
  
        &:hover {
          color: #52AEFE; // Tailwind's hover:text-blue-300
        }
      }
    }
  
    .mobile-menu-icon {
      display: none; // Hidden by default
      position: absolute;
      right: 1rem; // Adjust if needed
      top: 1rem; // Adjust if needed
  
      button {
        background: none;
        border: none;
        color: #333333; // Text color for the icon
  
        svg {
          width: 1.5rem; // Adjust if needed
          height: 1.5rem; // Adjust if needed
        }
      }
    }
  
    .mobile-menu {
      display: none; // Hidden by default
      margin-top: 1rem;
      background-color: #ffffff; // White background
      padding: 1rem;
  
      a {
        display: block;
        color: #000000; // Black text
        padding: 0.5rem 0;
        text-decoration: none;
        font-weight: bold; // Make text bold
  
        &:hover {
          color: #52AEFE; // Tailwind's hover:text-blue-300
        }
      }
    }
  
    // Show only on mobile
    @media (max-width: 768px) { // Adjust as needed
      .nav-links {
        display: none; // Hide nav links on mobile
      }
  
      .mobile-menu-icon {
        display: block; // Show mobile menu icon
      }
  
      .mobile-menu {
        display: block; // Show mobile menu
      }
  
      .logo {
        img {
          height: 2rem; // Smaller logo height on mobile
          margin-left: 1rem; // Adjust as needed
        }
      }
    }
  
    // Show on tablets and larger
    @media (min-width: 769px) { // Adjust as needed
      .nav-links {
        display: flex; // Show nav links
      }
  
      .mobile-menu {
        display: none; // Hide mobile menu
      }
  
      .mobile-menu-icon {
        display: none; // Hide mobile menu icon
      }
    }
  }
  