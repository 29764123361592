// src/components/login/LoginForm.scss
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f4;
    font-family: Arial, sans-serif;
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('../../../public/assets/patika.jpg') no-repeat center center;
    background-size: cover;
    background-attachment: fixed; // Pozadinska slika ostaje fiksna
    color: #000;
    padding: 50px 20px;
    box-sizing: border-box;
    z-index: 1;
  
    .login-form {
      background: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      width: 300px;
      text-align: center;
  
      h2 {
        margin-bottom: 20px;
        font-size: 24px;
        color: #333;
      }
  
      label {
        display: block;
        margin-bottom: 8px;
        font-size: 14px;
        color: #555;
      }
  
      input {
        width: calc(100% - 20px);
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 16px;
      }
  
      button {
        width: 100%;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  