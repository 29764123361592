.hero {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 80%;

  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    object-fit: cover;
    z-index: 0; // Video je u pozadini
  }

  .hero-content {
    position: relative;
    z-index: 1; // Sadržaj iznad videa
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    color: #fff;
    padding: 0 20px; // Dodajte padding ako je potrebno za mobilne uređaje
  }

  .hero-logo {
    width: 300px; // Veličina je smanjena da bolje stane na ekrane
    height: 300px;
    margin-top: 0rem; // Povećao sam marginu da izgleda uravnoteženije
    object-fit: contain; // Čuva proporcije logotipa
  }

  .hero-button-container {
    margin-top: 50px; // Prostor iznad dugmeta
    width: 100%; // Uverite se da kontejner dugmeta zauzima celu širinu
    display: flex;
    justify-content: center; // Centrirajte dugme
  }

  .hero-button {
    background-color: #007bff; // Boja dugmeta
    color: #333333; // Boja teksta
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: #EFF764; // Boja pri hoveru
      color: #FEFEFE; // Boja teksta pri hoveru
    }
  }

  // Media query za mobilne uređaje
  @media (max-width: 768px) {
    .hero {
      height: auto; // Automatski se prilagođava visini sadržaja
      margin-bottom: 0; // Uklonjen negativan margin
    }

    .hero-video {
      position: relative; // Promjena pozicije na "relative"
      width: 100%; // Širina videa se prilagođava širini ekrana
      height: auto; // Auto visina da zadrži odnos širine i visine
      object-fit: cover; // Pokriva cijeli ekran
      top: 0;
      left: 0;
    }

    .hero-logo {
      width: 300px; // Smanjena veličina logotipa za mobilne uređaje
      height: 300px;
      margin-top: 0; // Resetovano da nema prevelikog pomaka
    }

    .hero-content {
      padding: 20px; // Malo unutrašnjeg paddinga za bolji izgled na mobilnim uređajima
    }

    .hero-button-container {
      margin-top: 30px; // Smanjen razmak na manjim ekranima
    }

    .hero-button {
      font-size: 0.9rem; // Smanjena veličina dugmeta za mobilne uređaje
      padding: 8px 16px;
    }
  }

  // Media query za veoma male ekrane
  @media (max-width: 480px) {
    .hero-video {
      height: 50vh; // Video je manji za vrlo male ekrane
    }

    .hero-logo {
      width: 300px; // Još manje za male ekrane
      height: 300px;
      margin-top: -400px;
    }

    .hero-button {
      font-size: 0.8rem;
      padding: 6px 12px;
    }
  }
}