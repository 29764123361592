// Ponuda.scss

.product-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;

  .form-container {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin-bottom: 40px;

    h2 {
      font-size: 2rem;
      color: #333;
      margin-bottom: 20px;
      font-weight: 600;
      letter-spacing: 0.05rem;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .form-group {
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;

        label {
          font-size: 1rem;
          color: #555;
          margin-bottom: 8px;
          font-weight: 500;
        }

        input, textarea {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 8px;
          font-size: 1rem;
          color: #333;
          transition: border-color 0.3s ease;

          &:focus {
            border-color: #007bff;
            outline: none;
          }
        }

        textarea {
          resize: vertical;
          min-height: 100px;
        }

        .image-preview-container {
          position: relative;
          width: 100%;
          max-width: 100%;
          border-radius: 8px;
          margin-top: 10px;
          cursor: pointer;
          overflow: hidden;
          display: inline-block;

          .image-preview {
            width: 100%;
            height: auto;
            display: block;
            border-radius: 8px;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
          }

          &:hover .image-preview {
            transform: scale(1.05);
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
          }
        }

        .upload-button {
          display: block;
          width: 100%;
          max-width: 600px;
          padding: 12px;
          font-size: 1rem;
          color: #333;
          background-color: #e0e0e0; // Gray background
          border: none;
          border-radius: 8px;
          cursor: pointer;
          text-align: center;
          margin-top: 10px;
          position: relative;

          &:hover {
            background-color: #bdbdbd; // Darker gray on hover
            transform: translateY(-2px);
          }

          input[type="file"] {
            display: none;
          }
        }
      }
    }
  }

  .products-display {
    h2 {
      font-size: 2rem;
      color: #333;
      margin-bottom: 20px;
      font-weight: 600;
      letter-spacing: 0.05rem;
    }

    .products-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      gap: 20px;
      margin-top: 20px;
    }

    .product-item {
      background-color: #f9f9f9;
      border-radius: 12px;
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      position: relative;
      display: flex;
      flex-direction: column;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
      }

      .product-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-bottom: 1px solid #ddd;
        cursor: pointer;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
          transform: scale(1.1);
          box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
        }
      }

      .product-details {
        padding: 15px;

        h3 {
          font-size: 1.6rem;
          color: #333;
          margin-bottom: 10px;
          font-weight: 600;
        }

        p {
          font-size: 1rem;
          color: #555;
          margin: 5px 0;

          strong {
            color: #333;
          }
        }
      }

      .product-actions {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        border-top: 1px solid #ddd;
        background-color: #fff;

        .edit-button,
        .delete-button {
          padding: 8px 12px;
          font-size: 0.9rem;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s ease;
          color: #fff;
          flex: 1;
          margin: 0 5px;

          &:hover {
            opacity: 0.9;
          }
        }

        .edit-button {
          background-color: #28a745;
        }

        .delete-button {
          background-color: #dc3545;
        }
      }
    }
  }
}

// Responsive design tweaks for smaller screens
@media (max-width: 768px) {
  .ponuda-container {
    padding: 15px;

    .form-container {
      padding: 20px;
    }

    .products-list {
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }

    .product-item {
      .product-image {
        height: 150px;
      }
      .product-details h3 {
        font-size: 1.4rem;
      }
      .product-actions .edit-button,
      .product-actions .delete-button {
        font-size: 0.8rem;
      }
    }
  }
}

.submit-button {
  padding: 12px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff; // Blue background
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3; // Darker blue on hover
    transform: translateY(-2px);
  }
}
